import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Preview from 'components/Preview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "welcome-to-carbon-this-tutorial-will-guide-you-in-creating-a-react-app-with-the-carbon-design-system-well-teach-you-the-ins-and-outs-of-using-carbon-components-while-introducing-web-development-best-practices-along-the-way"
    }}>{`Welcome to Carbon! This tutorial will guide you in creating a React app with the Carbon Design System. We’ll teach you the ins and outs of using Carbon components, while introducing web development best practices along the way.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Audience</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Prerequisites</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Outline</AnchorLink>
    </AnchorLinks>
    <p>{`Here’s a `}<a parentName="p" {...{
        "href": "https://react-step-6--carbon-tutorial.netlify.com"
      }}>{`preview`}</a>{` of what you will build:`}</p>
    <Preview height="400" title="Carbon Tutorial" src="https://react-step-6--carbon-tutorial.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" mdxType="Preview" />
    <h2 {...{
      "id": "audience"
    }}>{`Audience`}</h2>
    <p>{`This tutorial is intended for people with all amounts of web development experience. If you want to jump straight to code, you may want to skip this tutorial and go to the `}<a parentName="p" {...{
        "href": "/get-started/develop"
      }}>{`developers getting started`}</a>{` page.`}</p>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <h3 {...{
      "id": "react"
    }}>{`React`}</h3>
    <p>{`This is a web development tutorial that uses the Carbon React components. If you’re just getting started with React and enjoy learning-by-doing, check out the official React `}<a parentName="p" {...{
        "href": "https://reactjs.org/tutorial/tutorial.html"
      }}>{`intro tutorial`}</a>{`. You’ll want to supplement that with their `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hello-world.html"
      }}>{`step-by-step guide`}</a>{`.`}</p>
    <h3 {...{
      "id": "es6es2015"
    }}>{`ES6/ES2015`}</h3>
    <p>{`React apps often use the latest and greatest from JavaScript (ES6/ES2015). You’ll want to be up to speed on the most commonly used features of the language. `}<a parentName="p" {...{
        "href": "https://www.youtube.com/playlist?list=PL57atfCFqj2h5fpdZD-doGEIs0NZxeJTX"
      }}>{`Let’s Learn ES6`}</a>{` covers those features in depth.`}</p>
    <h3 {...{
      "id": "github"
    }}>{`GitHub`}</h3>
    <p>{`We’ll be using GitHub to build an app together, so if you’re new to GitHub, make sure you’ve `}<a parentName="p" {...{
        "href": "https://github.com/join"
      }}>{`made an account`}</a>{`. Their `}<a parentName="p" {...{
        "href": "https://guides.github.com/activities/hello-world"
      }}>{`getting started guide`}</a>{` is a great way to learn GitHub.`}</p>
    <h3 {...{
      "id": "yarn"
    }}>{`Yarn`}</h3>
    <p>{`This tutorial uses `}<a parentName="p" {...{
        "href": "https://yarnpkg.com"
      }}>{`Yarn`}</a>{` for dependency management so we can take advantage of offline installs. Make sure that you have `}<a parentName="p" {...{
        "href": "https://yarnpkg.com/en/docs/install"
      }}>{`Yarn installed`}</a>{` prior to starting the tutorial so you can follow along step-by-step.`}</p>
    <h2 {...{
      "id": "outline"
    }}>{`Outline`}</h2>
    <p>{`Each step in this tutorial illustrates a different aspect of developing web applications with Carbon. We recommend starting with step 1, but you can pick up any step and take it from there.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorial/react/step-1"
        }}><strong parentName="a">{`Installing Carbon`}</strong></a><ul parentName="li">
          <li parentName="ul">{`Create a web app with the UI shell component.`}</li>
        </ul></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorial/react/step-2"
        }}><strong parentName="a">{`Building pages`}</strong></a><ul parentName="li">
          <li parentName="ul">{`Build out pages with the grid and various components.`}</li>
        </ul></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorial/react/step-3"
        }}><strong parentName="a">{`Using APIs`}</strong></a><ul parentName="li">
          <li parentName="ul">{`Populate the data table with an external data source.`}</li>
        </ul></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorial/react/step-4"
        }}><strong parentName="a">{`Creating components`}</strong></a><ul parentName="li">
          <li parentName="ul">{`Extend Carbon by creating your own components.`}</li>
        </ul></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorial/react/step-5"
        }}><strong parentName="a">{`Deploying to IBM Cloud`}</strong></a><ul parentName="li">
          <li parentName="ul">{`Build and host your app in a production environment.`}</li>
        </ul></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      